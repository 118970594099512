body.hiden {
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'Quicksand', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  background: $white;
  color: $black;
}

h1 {
  font-size: toRem(80);
  line-height: toRem(80);
}
h2 {
  font-size: toRem(50);
  line-height: toRem(60);
}
h3 {
  font-size: toRem(48);
  line-height: toRem(58);
}
h4 {
  font-size: toRem(38);
  line-height: toRem(45);
}
.breadcrumbs {
  margin-top: toRem(50);
}
.breadcrumbs__inner {
  display: flex;
  align-items: center;
  font-family: 'Lora', serif;
  font-size: toRem(14);
  line-height: toRem(16);
  font-weight: 500;
  text-transform: uppercase;
  gap: toRem(4);
  span {
    color: #808080;
  }
  a {
    color: $black;
  }
}
.counter-input {
  border-radius: toRem(4);
  border: 1px solid #6c7275;
  padding: 0 toRem(8);
  gap: toRem(13);
  width: 100%;
  height: toRem(32);
  max-width: toRem(80);
  display: flex;
  align-items: center;
  span {
    font-size: toRem(12);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
  }
  button {
    display: flex;
  }
}
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main {
  flex: 1 1 auto;
  margin-top: 94px;
}
.container {
  max-width: 1380px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
.container-right {
  padding: 0 calc(50% - 690px);
  padding-right: 0;
}
.header__menu-btn {
  display: none;
  margin-left: 20px;
  width: 25px;
  height: 21px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.header__menu-btn span {
  display: block;
  position: absolute;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 2px;
  width: 100%;
  background: $black;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.header__menu-btn span:nth-child(1) {
  top: 0px;
}

.header__menu-btn span:nth-child(2),
.header__menu-btn span:nth-child(3) {
  top: 9px;
  width: 100%;
}

.header__menu-btn span:nth-child(4) {
  top: 19px;
  width: 100%;
  right: 0;
  left: inherit;
}
.header__menu-btn.open span {
  border-radius: 10px;
}
.header__menu-btn.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
  display: none;
}

.header__menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  display: none;
}

.radius-btn {
  background-color: $black;
  border-radius: 38px;
  padding: 14px 27px;
  font-size: 19px;
  font-weight: 500;
  color: $white;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  width: fit-content;
  text-align: center;
}
.radius-btn:hover {
  color: $black;
  background-color: $white;
  border-color: $black;
}

.input {
  font-family: 'Lora', serif;
  padding: toRem(16);
  background-color: $white;
  border: 1px solid#D2D2D7;
  &::placeholder {
    color: #808080;
  }
}

.btn {
  font-family: 'Lora', serif;
  background: #121212;
  color: $white;
  padding: toRem(16) toRem(24) toRem(14);
  min-height: toRem(56);
  transition: all 0.3s ease;
  width: 100%;
  border: 1px solid transparent;
  font-weight: 500;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}
.btn:hover {
  background: $white;
  color: $black;
  border-color: $black;
}

.checkbox-item {
  padding-left: 27px;
  display: flex;
  align-items: center;
  .checkbox-radio {
    display: none;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_771)'%3E%3Cpath d='M5.80556 10.5388L6.20153 10.8748L6.52229 10.4664L10.2646 5.70152L10.5679 5.31529L10.1872 5.00507L9.51117 4.45426L9.11661 4.13277L8.80217 4.53297L5.9319 8.18606L4.06752 6.60389L3.67938 6.2745L3.35702 6.66849L2.80235 7.34642L2.49136 7.72652L2.86583 8.04428L5.80556 10.5388ZM0.5 7.32408C0.5 5.69792 1.13433 4.14018 2.26059 2.99306C3.38654 1.84626 4.91169 1.2037 6.5 1.2037C8.08831 1.2037 9.61346 1.84626 10.7394 2.99306C11.8657 4.14018 12.5 5.69792 12.5 7.32408C12.5 8.95023 11.8657 10.508 10.7394 11.6551C9.61346 12.8019 8.08831 13.4444 6.5 13.4444C4.91169 13.4444 3.38654 12.8019 2.26059 11.6551C1.13433 10.508 0.5 8.95023 0.5 7.32408Z' fill='black' stroke='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_771'%3E%3Crect width='13' height='13.2407' fill='white' transform='translate(0 0.703705)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      border-radius: 50%;
      left: -27px;
      top: 3px;
      transform: scale(0) rotateZ(180deg);
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background-color: #e0e0e0;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      left: -27px;
      top: 3px;
    }
  }
}

.checkbox-radio:checked + label::before {
  transform: scale(1) rotateZ(0deg);
}
.radiobutton-input {
  visibility: hidden;
  width: 0.01px;
  height: 0.01px;
  overflow: hidden;
  z-index: -10;
}
.radiobutton-label {
  display: flex;
  width: 100%;
}
.custom-radiobutton {
  height: toRem(18);
  width: toRem(18);
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #121212;
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: toRem(10);
    height: toRem(10);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 50%;
  }
}
.radiobutton-label-con {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: toRem(13) toRem(16);
  border: 1px solid #6c7275;
  border-radius: toRem(4);
  gap: toRem(12);
}
.radiobutton-input:checked + .radiobutton-label-con .custom-radiobutton::after {
  background-color: #121212;
}
.radiobutton-input:checked + .radiobutton-label-con {
  border-color: #141718;
}
.radiobutton-label:checked {
  background-color: red;
}
.radiobutton-label-custom {
  font-family: 'Inter', sans-serif;
  color: #141718;
  margin-left: auto;
  display: flex;
}
.cusom-input {
  padding: toRem(7) toRem(16);
  background-color: $white;
  border: 1px solid #cbcbcb;
  border-radius: toRem(6);
  width: 100%;
  font-family: 'Inter', sans-serif;
  line-height: toRem(26);
  height: toRem(40);
  color: #121212;
  &::placeholder {
    color: #6c7275;
  }
}
.custom-select {
  height: toRem(40);
  padding: toRem(7) toRem(16);
  background-color: $white;
  border: 1px solid #cbcbcb;
  border-radius: toRem(6);
  width: 100%;
  font-family: 'Inter', sans-serif;
  line-height: toRem(26);
  color: #6c7275;
}
