$black: #000;
$white: #fff;
$bg-gray: #ededed;
$dark-text: #1f1f1f;


@function toRem($value) {
    $remValue: ($value / 16) + rem; 
    @return $remValue;
}
