@media (max-width: 1200px) {
  .contact-mid {
    margin: toRem(50) 0;
  }
  .faq__inner-list {
    padding: toRem(20);
    padding-right: toRem(0);
  }
  .faq__inner-head {
    padding: toRem(20);
    padding-left: toRem(0);
  }
  h4 {
    font-size: toRem(30);
    line-height: toRem(35);
  }
  .faq__inner-title {
    font-size: toRem(30);
    line-height: toRem(40);
  }
}
@media (max-width: 1024px) {
  .prod-detail-con-info-description-text,
  .prod-detail-con-info-growing-title {
    font-size: toRem(20);
    line-height: toRem(25);
  }
  .prod-detail-con-info-title {
    font-size: toRem(25);
    line-height: toRem(30);
  }
  .prod-detail-con-img {
    height: toRem(500);
  }
  .privat-policy-info {
    padding: toRem(20);
  }
  .contact-footer {
    padding: toRem(15);
  }
  .contact-head {
    padding: toRem(15);
  }
  h2 {
    font-size: toRem(40);
    line-height: toRem(50);
  }
  .contact-mid {
    margin: toRem(25) 0;
  }
  .faq__inner-text {
    font-size: toRem(18);
    line-height: toRem(25);
  }
  h4 {
    font-size: toRem(25);
    line-height: toRem(30);
  }
  .shop-swiper-slider-con-img {
    height: toRem(450);
  }
  h3 {
    font-size: toRem(35);
    line-height: toRem(40);
  }
  .banner__inner-title {
    font-size: toRem(30);
  }
  .banner::after {
    height: toRem(250);
  }
  .seed-adventure-learn-more p {
    font-size: toRem(18);
    margin-bottom: toRem(20);
  }
  .seed-adventure-learn-more a {
    font-size: toRem(16);
  }
  .seed-adventure-col {
    padding-top: toRem(20);
    padding-bottom: toRem(20);
  }
  .seed-adventure-title {
    font-size: toRem(25);
    line-height: toRem(30);
  }
  .header-content-box h1 {
    margin-bottom: toRem(40);
  }
  .header-content-box span {
    margin-top: toRem(35);
  }
  h1 {
    font-size: toRem(50);

    line-height: toRem(60);
  }

  .footer__inner-panel-title {
    font-size: toRem(25);
    line-height: toRem(35);
  }
  .footer__inner-panel {
    padding: toRem(15);
    margin-bottom: toRem(25);
  }
  .shopping-cart-summary {
    max-width: toRem(350);
  }
  .checkout-details {
    gap: toRem(20);
  }
  .checkout-details-form-list-col {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .order-complete-subtitle {
  }
  .order-complete-title {
    font-size: toRem(30);
    line-height: toRem(40);
  }
  .order-complete {
    padding: toRem(30);
  }
  .checkout-details-order {
    max-width: 100%;
  }
  .checkout-details {
    flex-direction: column;
  }
  .shopping-cart-summary {
    max-width: 100%;
  }
  .shopping-cart {
    flex-direction: column;
    gap: toRem(30);
  }
  .header__inner > .header__inner-link-item {
    margin-left: auto;
  }
  .auth-box {
    padding: toRem(40);
  }
  .prod-detail-con-info-description-text,
  .prod-detail-con-info-growing-title {
    font-size: toRem(18);
  }
  .prod-detail-con-info-growing,
  .prod-detail-con-info-description {
    gap: toRem(5);
  }
  .prod-detail-con-info {
    gap: toRem(10);
  }
  .prod-detail-con-info-title {
    font-size: toRem(20);
    line-height: toRem(25);
  }
  .prod-detail-con-info-col {
    flex-direction: column;
    align-items: flex-start;
    gap: toRem(15);
  }
  .shop-panel-img {
    order: 1;
  }
  .shop {
    flex-direction: column;
  }
  .privat-policy {
    flex-direction: column;
  }
  .contact-img {
    max-width: 100%;
  }
  .contact-wrapper {
    flex-direction: column;
  }
  h2 {
    font-size: toRem(30);
    line-height: toRem(40);
  }
  .shop-swiper-slider-icons {
    right: toRem(20);
  }
  .block-subscribe {
    margin-bottom: toRem(50);
  }
  .shop-swiper-slider-con-img {
    height: toRem(400);
  }
  .shop-swiper-head-label {
    font-size: toRem(18);
    line-height: toRem(23);
  }
  .shop-swiper-head {
    gap: toRem(20);
  }
  h3 {
    font-size: toRem(25);
    line-height: toRem(30);
  }
  .banner {
    margin-bottom: toRem(25);
    padding-bottom: toRem(20);
  }
  .banner__inner-title {
    font-size: toRem(25);
    line-height: toRem(30);
    margin-top: toRem(30);
  }
  .banner::after {
    height: toRem(150);
  }
  .header-content-box span {
    font-size: toRem(18);
  }
  h1 {
    font-size: toRem(30);
    line-height: toRem(40);
  }
  .footer__inner-con {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: toRem(50);
  }
  .footer .footer__inner .footer__inner-links {
    margin-left: toRem(0);
    margin-top: toRem(30);
  }
  .main {
    margin-top: 81px;
  }

  body {
    font-size: 14px;
  }

  .header {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
  }
  .header__inner {
    min-height: 80px;
  }
  .header__inner .header__inner-logo a {
    width: 100px;
  }
  .header__menu-btn {
    display: block;
    z-index: 2;
  }
  .header__inner.active {
    .header__menu-btn span {
      background: $white;
    }
    .header__inner-logo a svg path {
      fill: $white;
    }
    .header__inner-link {
      margin-left: 0px;
      svg path:first-child {
        fill: $white;
      }
      svg path:last-child {
        stroke: $white;
      }
      svg path {
        fill: $white;
      }
      a + a {
        margin-left: 35px;
      }
    }
  }
  .header__inner-nav .global-btn {
    display: block;
    width: 100%;
  }
  .header__inner-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: $black;
    left: 0;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 0%;
    padding: 60px 20px 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -100%);
  }
  .header__inner-nav.active {
    transform: translate(0px, 0px);
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 2;

    .nav-list-item.drop-down {
      .nav-list-item-btn {
        color: $white;
        svg path {
          fill: $white;
        }
      }
    }
    .nav-list-item.drop-down.active {
      .nav-list-item-drop-down {
        position: static;
        background-color: transparent;
        display: flex;
        ul li a {
          text-align: left;
        }
      }
    }
    .nav-list {
      flex-direction: column;
      width: 100%;
      margin: auto 0px;
      align-items: flex-start;
      overflow-y: auto;
      > a {
        margin-left: 0px;
        margin-top: 15px;
      }
      .nav-list-item {
        a {
          width: 100%;
          color: $white;
          display: block;
          text-align: center;
        }
      }
      .nav-list-item + .nav-list-item {
        margin-left: 0px;
        margin-top: 15px;
      }
    }

    .nav-list .nav-list-item .nav-list-item-drop-down ul li a {
      white-space: normal;
    }

    .nav-list-item-drop-down {
      max-width: 100%;
      transition: none !important;
      display: none;
    }
  }
  .cart {
    margin-top: toRem(20);
    margin-bottom: toRem(50);
  }
  .cart-tabs {
    gap: toRem(30);
  }
  .cart__inner-title {
    margin-bottom: toRem(20);
    font-size: toRem(40);
    line-height: toRem(50);
  }
}
@media (max-width: 600px) {
  .prod-detail-con-img {
    height: toRem(300);
  }
  .prod-detail-con {
    flex-direction: column;
  }
  .faq__inner {
    flex-direction: column;
  }
  .banner__inner-title {
    font-size: toRem(20);
    line-height: toRem(25);
  }
  .banner::after {
    height: toRem(70);
  }
  .seed-adventure__inner {
    flex-direction: column;
  }
  .footer__inner-info .footer__inner-info-title {
    font-size: toRem(30);
    line-height: toRem(35);
  }
  .footer__inner-panel {
    flex-direction: column;
    gap: toRem(20);
  }
  .footer__inner-panel-contact {
    max-width: 100%;
  }
  .footer__inner-panel-title {
    font-size: toRem(20);
    line-height: toRem(30);
  }
  .contact .contact__inner p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
    max-width: 185px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .checkbox-item {
    padding-left: 18px;
  }
  .checkbox-item label::after {
    left: -18px;
  }
  .checkbox-item label::before {
    left: -18px;
  }
}
@media (max-width: 500px) {
  .auth-box-resend {
    flex-direction: column;
  }
  .auth-box {
    padding: toRem(20);
  }
  .shop-list-item-img {
    height: toRem(200);
  }
  .contact-head-input {
    flex-direction: column;
  }
  h4 {
    font-size: toRem(23);
    line-height: toRem(28);
  }
  .shop-swiper-slider-con-img {
    height: toRem(300);
  }
  .block-subscribe__inner {
    flex-direction: column;
  }
  .block-subscribe__inner-input {
    width: 100%;
  }
  .block-subscribe__inner-input input {
    width: 100%;
  }
  .cart-tabs .tabs {
    flex-direction: column;
    gap: toRem(20);
  }
  .cart-tabs .tabs .tab-button {
    width: 100%;
    padding-bottom: toRem(10);
  }
  .order-complete-img-list {
    gap: toRem(20);
  }
  .order-complete-list .order-complete-list-item span:first-child {
    width: toRem(80);
  }
  .order-complete {
    padding: toRem(15);
  }
  .order-complete-img-list .order-complete-img-list-item {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .shop-list-item-info {
    padding: toRem(5);
  }
  .header-content-box span {
    margin-bottom: toRem(0);
  }
  .header-content-box {
    margin-bottom: toRem(30);
  }
  .header-content-box h1 {
    margin-top: toRem(40);
  }
  .header-content__inner {
    flex-direction: column;
  }
}
