@import 'vars';
@import 'reset';
@import 'global';
.header {
  border-bottom: 1px solid $black;
  background-color: $white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 93px;
  > .header__inner-link-item {
    min-width: toRem(24);
    min-height: toRem(24);
    margin-left: 33px;
    button {
      display: flex;
    }
  }
  .header__inner-logo {
    position: relative;
    z-index: 3;
    a {
      display: flex;
    }
  }
}
.header__inner-nav {
  display: flex;
  align-items: center;
  width: 100%;
  .nav-list {
    display: flex;
    align-items: center;
    margin: 0 auto;
    > li {
      & + li {
        margin-left: 77px;
      }
      a {
        color: $dark-text;
        font-weight: 500;
        letter-spacing: -0.444px;
      }
      a:hover {
        color: $black;
      }
      a.active {
        font-weight: 500;
        color: $black;
      }
    }
    > a {
      margin-left: 48px;
    }
    .nav-list-item {
      position: relative;

      .nav-list-item-drop-down {
        visibility: hidden;
        z-index: 1;
        transform: translate(0px, 10%);
        opacity: 0;
        position: absolute;
        left: -15px;
        top: 40px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
        padding: 8px 0px;
        pointer-events: none;
        transition: all 0.3s ease;
        min-width: 150px;
        ul li {
          display: flex;
          a {
            padding: 5px 20px;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
      &.drop-down.active {
        .nav-list-item-drop-down {
          visibility: visible;
          transform: translate(0px, 0px);
          opacity: 1;
          pointer-events: all;
        }
        .nav-list-item-btn svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    .nav-list-item.drop-down .nav-list-item-btn {
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 20px;
      cursor: pointer;
      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 7px;
        transition: all 0.3s ease;
      }
    }
  }
}
.header__inner-link {
  display: flex;
  align-items: center;
  a {
    display: flex;
  }
  button {
    display: flex;
  }
  .header__inner-link-item {
    display: flex;
  }
  .header__inner-link-item + .header__inner-link-item {
    margin-left: 33px;
  }
}
.header-content__inner {
  display: flex;
  justify-content: space-between;
}
.header-content-box {
  display: flex;
  flex-direction: column;
  h1 {
    font-family: 'Belleza', sans-serif;
    margin-bottom: 73px;
    max-width: 685px;
    width: 100%;
    letter-spacing: -0.5px;
    font-weight: 400;
    margin-top: 100px;
  }
  a {
    max-width: 178px;
    width: 100%;
  }
  span {
    margin-top: toRem(65);
    margin-bottom: toRem(20);
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
}
.header-content-img {
  margin-top: auto;
}

.seed-adventure {
  margin-bottom: 77px;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
}
.seed-adventure__inner {
  display: flex;
}
.seed-adventure-col {
  width: 100%;
  & + & {
    border-left: 1px solid $black;
    padding-left: 12px;
  }
  padding-top: 62px;
  padding-bottom: 44px;
}
.seed-adventure-title {
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  font-family: 'Lora', serif;
}
.seed-adventure-learn-more {
  p {
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    max-width: 630px;
    width: 100%;
    margin-bottom: 45px;
  }
  a {
    display: flex;
    align-items: center;
    color: $black;
    font-size: 20px;
    text-transform: uppercase;
    svg {
      margin-left: 30px;
    }
  }
}
.banner {
  background-color: $bg-gray;
  position: relative;
  margin-bottom: 70px;
  padding-bottom: 50px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 293px;
    background-color: $white;
  }
}
.banner__inner {
  img {
    position: relative;
    z-index: 1;
  }
}
.banner__inner-title {
  font-family: 'Lora', serif;
  font-size: toRem(36);
  font-weight: 500;
  line-height: toRem(36);
  margin-top: toRem(60);
  text-align: center;
}

.block-subscribe {
  margin-bottom: toRem(150);
}
.block-subscribe__inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: toRem(30);
  h3 {
    font-family: 'Lora', serif;
    max-width: toRem(703);
    width: 100%;
    font-weight: 500;
  }
}
.block-subscribe__inner-input {
  position: relative;
  input {
    padding: toRem(18) 0;
    padding-left: toRem(7);
    padding-right: toRem(126);
    font-weight: 300;
    border-bottom: 2px solid $black;
    &::placeholder {
      color: $black;
    }
  }

  .radius-btn {
    padding: toRem(9) toRem(29);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: toRem(14);
    line-height: toRem(14);
  }
}

.faq {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
}
.faq__inner {
  display: flex;
}
.faq__inner-head {
  padding: toRem(80);
  padding-left: toRem(30);
}
.faq__inner-title {
  font-family: 'Lora', serif;
  font-size: toRem(50);
  line-height: toRem(60);
  font-weight: 500;
}
.faq__inner-text {
  font-size: toRem(20);
  line-height: 232%;
}
.faq__inner-col {
  width: 100%;
  & + & {
    border-left: 1px solid $black;
  }
}
.faq__inner-lists {
  display: flex;
  flex-direction: column;
}
.faq__inner-list {
  padding: toRem(80);
  padding-right: toRem(30);
  font-family: 'Lora', serif;
  & + & {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 999%;
      height: 1px;
      background-color: $black;
    }
  }
  h4 {
    font-weight: 500;
    margin-bottom: toRem(16);
  }
  p {
    font-weight: 400;
    color: rgba(18, 18, 18, 0.9);
  }
}
.shop-swiper {
  padding-top: toRem(53);
  padding-bottom: toRem(20);
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  margin-bottom: toRem(34);
}

.shop-swiper-head {
  display: flex;
  flex-direction: column;
  gap: toRem(36);
  margin-bottom: toRem(52);
  h3 {
    font-family: 'Lora', serif;
    font-weight: 500;
    max-width: toRem(526);
    width: 100%;
  }
  a {
    max-width: toRem(134);
    width: 100%;
    padding: toRem(10) toRem(20);
  }
}
.shop-swiper-head-label {
  font-size: toRem(20);
  font-weight: 500;
  letter-spacing: toRem(2);
  text-transform: uppercase;
}
.shop-swiper-slider {
  padding-left: 20px;
}
.shop-swiper-slider-con {
  display: flex;
  flex-direction: column;
  margin-bottom: toRem(92);
}
.shop-swiper-slider-con-img {
  height: toRem(577);
  margin-bottom: toRem(18);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.shop-swiper-slider-con-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: toRem(10);
}
.shop-swiper-slider-con-footer-title {
  font-size: toRem(18);
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $black;
}
.shop-swiper-slider-con-footer-price {
  font-size: toRem(20);
  white-space: nowrap;
  color: $black;
}
.shop-swiper-slider-icons {
  position: absolute;
  bottom: 0;
  right: toRem(50);
  z-index: 1;
  display: flex;
  gap: toRem(6);
  .prev,
  .next {
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .prev.swiper-button-disabled,
  .next.swiper-button-disabled {
    opacity: 0.5;
  }
}

.shop {
  display: flex;
}
.shop-panel-img {
  width: 100%;
  display: flex;
  flex-direction: column;
  img + img {
    margin: auto 0;
  }
}
.shop-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: fit-content;
}
.shop-list-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.shop-list-item-img {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.shop-list-item-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  border: 1px solid $black;
  padding: toRem(11) toRem(27);
  font-family: 'Lora', serif;
}
.shop-list-item-info-title {
  text-transform: uppercase;
  font-weight: 500;
  color: $black;
  margin-bottom: toRem(2);
  text-align: center;
}
.shop-list-item-info-price {
  color: #959595;
  font-size: toRem(14);
  font-weight: 500;
}

.prod-detail {
  margin: toRem(50) 0 toRem(120);
  font-family: 'Lora', serif;
}
.prod-detail-con {
  display: flex;
  gap: toRem(26);
}
.prod-detail-con-img {
  width: 100%;
  height: toRem(675);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.prod-detail-con-info {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: toRem(20);
}
.prod-detail-con-info-title {
  margin-bottom: toRem(20);
  font-size: toRem(33);
  line-height: toRem(40);
  font-weight: 700;
  text-transform: uppercase;
}
.prod-detail-con-info-description {
  display: flex;
  flex-direction: column;
  gap: toRem(20);
  p {
    font-weight: 400;
    color: rgba(18, 18, 18, 0.9);
    line-height: toRem(24);
  }
}
.prod-detail-con-info-description-text {
  font-size: toRem(24);
  font-weight: 700;
  line-height: toRem(28);
}
.prod-detail-con-info-growing {
  gap: toRem(20);
  display: flex;
  flex-direction: column;
  ul {
    display: flex;
    flex-direction: column;
    li {
      font-weight: 600;
      line-height: toRem(22);
      color: rgba(18, 18, 18, 0.9);
      span {
        font-weight: 500;
      }
    }
  }
}
.prod-detail-con-info-growing-title {
  font-size: toRem(24);
  font-weight: 700;
  line-height: toRem(28);
}
.prod-detail-con-info-col {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.prod-detail-con-info-counter {
  display: flex;
  align-items: center;
  font-size: toRem(18);
  font-weight: 500;
  line-height: toRem(25);
}
.counter {
  height: toRem(44);
  border: 1px solid $black;
  display: flex;
  align-items: center;
  margin-left: toRem(16);
  width: toRem(146);
  justify-content: space-between;

  button {
    width: toRem(44);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .counter-minus {
    border-right: 1px solid $black;
  }
  .counter-plus {
    border-left: 1px solid $black;
  }
}
.prod-detail-con-info-price {
  font-size: toRem(20);
  font-weight: 500;
  color: #959595;
}
.privat-policy {
  display: flex;
}
.privat-policy-img {
  width: 100%;
  img {
    width: 100%;
  }
}
.privat-policy-info {
  width: 100%;
  border-left: 1px solid $black;
  border-bottom: 1px solid $black;
  padding: toRem(38) toRem(80);
}
h2 {
  font-family: 'Belleza', sans-serif;
  margin-bottom: toRem(24);
  color: #121212;
}
.privat-policy-info-description {
  display: flex;
  flex-direction: column;
  gap: toRem(30);
  p {
    color: rgba(18, 18, 18, 0.9);
    font-family: 'Lora', serif;
    line-height: toRem(22);
  }
}
.auth {
  width: 100%;
  height: 100%;
  padding: toRem(80) 0;
}
.auth-container {
  max-width: 1380px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
.auth__inner {
  display: flex;
  height: 100%;
}
.auth-box {
  background-color: $white;
  padding: toRem(80);
  margin: auto;
  max-width: toRem(722);
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    margin-bottom: toRem(24);
  }
}
.auth-box-input {
  margin-bottom: toRem(16);
  display: flex;
  flex-direction: column;
  label {
    font-family: 'Lora', serif;
    margin-bottom: toRem(12);
    font-size: toRem(16);
    line-height: toRem(19);
    font-weight: 500;
    cursor: default;
    color: #121212;
  }
}

.auth-box-description {
  margin-bottom: toRem(24);

  font-size: toRem(16);
  line-height: toRem(19);
  font-weight: 500;
  color: rgba(18, 18, 18, 0.9);
}
.auth-box.sing {
  .auth-box-input {
    input {
      font-family: 'Quicksand', sans-serif;
    }
    label {
      font-family: 'Quicksand', sans-serif;
    }
  }
  .btn {
    font-family: 'Quicksand', sans-serif;
  }
}
.auth-box-input-area {
  margin-top: toRem(8);
  font-size: toRem(12);
  font-weight: 500;
  line-height: toRem(14);
  color: #808080;
}
.auth-box-resend {
  display: flex;
  align-items: center;
  gap: toRem(8);
  font-size: toRem(16);
  font-weight: 500;
  line-height: toRem(19);
  margin-top: toRem(24);
  color: #808080;

  a {
    color: #121212;
  }
}
.auth-box-tel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: toRem(24);
  border-bottom: 1px solid #121212;
  margin-bottom: toRem(16);
}
.auth-box-tel-input {
  display: flex;
  gap: toRem(4);
  color: #424245;
  font-weight: 500;
  font-size: toRem(18);
  line-height: toRem(25);
}
.contact {
  border-bottom: 1px solid $black;
}
.contact-wrapper {
  display: flex;
  align-items: center;
}
.contact-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contact-head {
  max-width: toRem(610);
  width: 100%;
  margin: 0 auto;
  padding: toRem(25);
}
.contact-head-text {
  font-size: toRem(18);
  line-height: toRem(25);
  font-weight: 500;
  font-family: 'Lora', serif;
  margin-bottom: toRem(16);
}
.contact-head-input {
  display: flex;
  width: 100%;
  gap: toRem(16);
}
.contact-mid {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  padding: toRem(16) toRem(10);
  text-align: center;
  margin: toRem(100) 0;
}
.contact-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: toRem(14);
  padding: toRem(25);
  font-family: 'Lora', serif;
}
.contact-footer-tel {
  a {
    color: #121212;
    font-weight: 600;
  }
}
.contact-footer-address {
  color: #121212;
  max-width: toRem(245);
  width: 100%;
  text-align: center;
  font-weight: 600;
}
.contact-img {
  max-width: toRem(720);
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.shop-cart-bg {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  position: fixed;
}
.shop-cart-wrapper {
  position: fixed;
  max-width: toRem(413);
  width: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100%;
  padding-bottom: toRem(120);
}
.shop-cart {
  background-color: $white;
  width: 100%;
  height: 100%;
  padding: toRem(40) toRem(25);
  display: flex;
  flex-direction: column;
}
.shop-cart-title {
  font-size: toRem(28);
  line-height: toRem(34);
  font-family: 'Belleza', sans-serif;
  letter-spacing: -0.6px;
  margin-bottom: toRem(16);
}
.shop-cart-checkout {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.shop-cart-checkout-subtotal {
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: toRem(13) 0;
  border-bottom: 1px solid #e8ecef;
  color: #141718;
  .shop-cart-checkout-subtotal-text {
    font-weight: 400;
  }
  .shop-cart-checkout-subtotal-price {
    font-weight: 600;
  }
}
.shop-cart-checkout-total {
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: toRem(13) 0;
  margin-bottom: toRem(16);
  font-size: toRem(20);
  font-weight: 500;
  line-height: toRem(28);
}
.shop-cart-checkout-btn {
  background-color: #141718;
  border-radius: toRem(6);
  padding: toRem(10) toRem(26);
  font-family: 'Inter', sans-serif;
  font-size: toRem(18);
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: toRem(32);
  text-align: center;
  color: $white;
  margin-bottom: toRem(16);
  transition: all 0.3s ease;
  border: 1px solid transparent;

  &:hover {
    background-color: $white;
    color: #141718;
    border-color: #141718;
  }
}
.shop-cart-checkout-link {
  font-family: 'Inter', sans-serif;
  font-size: toRem(14);
  font-weight: 600;
  line-height: toRem(22);
  text-decoration: underline !important;
  text-align: center;
  color: #121212;
}
.shop-cart-items {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.shop-cart-item {
  display: flex;
  justify-content: space-between;
  padding: toRem(25) 0;
  color: #141718;
  border-bottom: 1px solid #e8ecef;
}
.shop-cart-item-left {
  display: flex;
  gap: toRem(16);
}
.shop-cart-item-img {
  width: 80px;
  height: 96px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.shop-cart-item-left-info {
  display: flex;
  flex-direction: column;
  gap: toRem(8);
}
.shop-cart-item-title {
  font-family: 'Inter', sans-serif;
  font-size: toRem(13);
  font-weight: 500;

  line-height: toRem(22);
}
.shop-cart-item-right {
  display: flex;
  flex-direction: column;
}
.shop-cart-item-right-price {
  font-size: toRem(14);
  line-height: toRem(22);
  font-weight: 600;
  font-family: 'Lora', serif;
}
.cart {
  margin-top: toRem(45);
  margin-bottom: toRem(110);
}
.cart__inner {
  display: flex;
  flex-direction: column;
}
.cart__inner-title {
  margin-bottom: toRem(40);
  font-size: toRem(54);
  line-height: toRem(58);
  letter-spacing: -1px;
  font-family: 'Belleza', sans-serif;
  text-align: center;
}
.cart-tabs {
  display: flex;
  flex-direction: column;
  gap: toRem(80);
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(32);
    .tab-button {
      display: flex;
      align-items: center;
      gap: toRem(17);
      padding-bottom: toRem(25);
      border-bottom: 2px solid transparent;
      opacity: 0.5;
      transition: all 0.3s ease;
      span {
        min-width: toRem(40);
        min-height: toRem(40);
        border-radius: toRem(40);
        background-color: #23262f;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        line-height: toRem(26);
      }
    }
    .tab-button.active {
      border-bottom: 2px solid #141718;
      opacity: 1;
    }
  }
}
.tabs-content {
  max-width: toRem(1120);
  width: 100%;
  margin: 0 auto;
}

.shopping-cart {
  display: flex;
  gap: toRem(65);
}
.shopping-cart-table {
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    th {
      padding-bottom: toRem(24);
      border-bottom: 1px solid #e8ecef;
      text-align: left;
      font-family: 'Lora', serif;
      font-weight: 600;
      color: #121212;
    }
    th:first-child {
      width: 50%;
    }
    th:last-child {
      text-align: right;
    }
    td {
      padding: toRem(24) 0;
      border-bottom: 1px solid #e8ecef;
    }
    td:last-child {
      text-align: right;
    }

    td + td,
    th + th {
      padding-left: 10px;
    }
  }
  .shop-cart-item-title {
    font-family: 'Lora', serif;
  }
  .price {
    font-family: 'Inter', sans-serif;
    font-size: toRem(18);
    line-height: toRem(30);
    color: #121212;
    white-space: nowrap;
  }
  .subtotal {
    font-family: 'Inter', sans-serif;
    font-size: toRem(18);
    line-height: toRem(30);
    font-weight: 600;
    color: #121212;
    white-space: nowrap;
  }
}
.btn-close {
  display: flex;
  align-items: center;
  text-align: left;
}

.radio-list {
  display: flex;
  flex-direction: column;
  gap: toRem(12);
  margin-bottom: toRem(16);
}
.shopping-cart-summary {
  padding: toRem(25);
  max-width: toRem(413);
  width: 100%;
  height: fit-content;
  border: 1px solid #6c7275;
  border-radius: toRem(6);
  display: flex;
  flex-direction: column;

  .shop-cart-checkout-btn {
    margin-bottom: toRem(0);
  }
}
.shopping-cart-summary-title {
  margin-bottom: toRem(16);
  font-size: toRem(20);
  line-height: toRem(28);
  font-family: 'Lora', serif;
  font-weight: 500;
}
.cusom-input-con {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: toRem(12);
  label {
    font-family: 'Inter', sans-serif;
    cursor: default;
    font-size: toRem(12);
    line-height: toRem(12);
    font-weight: 700;
    color: #6c7275;
    text-transform: uppercase;
  }
}
.checkout-details {
  display: flex;
  gap: toRem(65);
}
.checkout-details-list-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: toRem(24);
}
.checkout-details-order {
  max-width: toRem(413);
  width: 100%;
  padding: toRem(16) toRem(24);
  gap: toRem(16);
  display: flex;
  flex-direction: column;
  border: 1px solid #6c7275;
  border-radius: toRem(6);
  height: fit-content;
}
.checkout-details-form {
  padding: toRem(40) toRem(24);
  border: 1px solid #6c7275;
  border-radius: toRem(6);

  .radio-list {
    margin-bottom: toRem(24);
    padding-bottom: toRem(32);
    border-bottom: 1px solid #6c7275;
    .radiobutton-label-con {
      font-family: 'Inter', sans-serif;
    }
  }
}
.checkout-details-form-title {
  font-size: toRem(20);
  line-height: toRem(28);
  font-weight: 500;
  font-family: 'Lora', serif;
  margin-bottom: toRem(24);
}
.checkout-details-form-list {
  display: flex;
  flex-direction: column;
  gap: toRem(24);
}
.checkout-details-form-list-col {
  display: flex;
  gap: toRem(24);
  width: 100%;
}
.checkout-details-order-title {
  font-family: 'Lora', serif;
  font-size: toRem(28);
  font-weight: 500;
  line-height: toRem(34);
  margin-bottom: toRem(16);
  letter-spacing: -0.6px;
}
.checkout-details-order-list {
  display: flex;
  flex-direction: column;
  .shop-cart-item-right {
    button {
      display: none;
    }
  }
}
.checkout-details-order-footer {
  .shop-cart-checkout-subtotal {
    font-family: 'Lora', serif;
  }
  .shop-cart-checkout-total {
    font-family: 'Lora', serif;
  }
}
.order-complete {
  max-width: toRem(738);
  width: 100%;
  margin: 0 auto;
  padding: toRem(80) toRem(95);
  background-color: #fff;
  box-shadow: 0px 32px 48px -12px rgba(18, 18, 18, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lora', serif;
  .btn {
    width: fit-content;
    border-radius: toRem(80);
    padding: toRem(12) toRem(40);
  }
}
.order-complete-subtitle {
  font-size: toRem(28);
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: toRem(34);
  margin-bottom: toRem(16);
  color: #6c7275;
}
.order-complete-title {
  font-size: toRem(40);
  font-weight: 500;
  line-height: toRem(44);
  letter-spacing: -0.4px;
  color: #23262f;
  margin-bottom: toRem(55);
  max-width: toRem(492);
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: toRem(40);
}
.order-complete-img-list {
  display: flex;
  gap: toRem(55);
  margin-bottom: toRem(40);
  .order-complete-img-list-item {
    width: toRem(80);
    height: toRem(95);
    position: relative;
    span {
      font-family: 'Inter', sans-serif;
      top: -15px;
      right: -15px;
      position: absolute;
      width: toRem(32);
      height: toRem(32);
      border-radius: 100%;
      background-color: #141718;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.order-complete-list {
  display: flex;
  flex-direction: column;
  gap: toRem(20);
  margin-bottom: toRem(40);
  .order-complete-list-item {
    display: flex;
    align-items: center;
    span {
      font-size: toRem(14);
      font-weight: 600;
      line-height: toRem(22);
      color: #6c7275;
    }
    span:first-child {
      width: toRem(120);
    }
    span:last-child {
      color: #141718;
      margin-left: toRem(32);
      font-family: 'Quicksand', sans-serif;
    }
  }
}

.footer {
  background-color: $bg-gray;
  color: $black;
  padding-bottom: 50px;
  margin-top: 30px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98px;
    background-color: $white;
  }
  .container {
    max-width: 1320px;
  }
  .footer__inner {
    display: flex;
    flex-direction: column;

    .footer__inner-logo {
      margin-bottom: 45px;
      a {
        display: flex;
        justify-content: center;
      }
      a svg {
        width: 180px;
      }
      a path {
        fill: $white;
      }
    }
    .footer__inner-links {
      display: flex;
      align-items: flex-start;
      gap: toRem(100);
      margin-left: toRem(40);
      .footer__inner-links-item {
        display: flex;
        flex-direction: column;

        ul li {
          display: flex;
          align-items: flex-start;
          & + li {
            margin-top: toRem(13);
          }
          a {
            color: rgba(0, 0, 0, 0.6);
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.footer__inner-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 110px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.footer__inner-info {
  display: flex;
  flex-direction: column;
  .footer__inner-info-title {
    font-family: 'Belleza', sans-serif;
    font-size: 40px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    max-width: 845px;
    width: 100%;
  }
}
.footer__inner-panel {
  padding: 36px 64px;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  z-index: 1;
}
.footer__inner-panel-title {
  font-family: 'Lora', serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
  color: $white;
  max-width: 550px;
  width: 100%;
}
.footer__inner-panel-contact {
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  .footer__inner-panel-contact-input {
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }
    input {
      width: 100%;
      padding: 12px 16px;
      padding-left: 52px;
      background-color: $white;
      color: $black;
    }
    input::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  button {
    padding: 12px 16px;
    background-color: $white;
    width: 100%;
    font-weight: 500;
  }
}
@import 'media';
